export const getCategory = (category) => {
  switch (category) {
    case 'how to promote':
      return 'How to promote games';
    case 'gamedev guides':
      return 'Guides for gamedevs';
    case 'streamer guides':
      return 'Guides for streamers';
    case 'how to grow':
      return 'How to grow as a streamer';
    case 'gamers':
      return 'For game lovers';
    default:
      return '';
  }
}
