import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PostWrapper } from "./";
import { PostCardTitle, PostCardDescription } from "../";
import AuthorBlock from "../Author/AuthorBlock";
import { ImageWrapper } from "./ImageWrapper";
import { Badge } from "../Badge";
import {getCategory} from "../../utils";

export function PostCard(prop) {
  const {
    node: {
      frontmatter,
      timeToRead,
      fields: { slug },
    },
  } = prop;
  const image = getImage(frontmatter.background_image);
  const author = {
    name: frontmatter.author_name,
    avatar: frontmatter.author_avatar,
    date: frontmatter.publication_date,
    timeToRead,
  };

  const currentCategory = getCategory(frontmatter.category)

  return (
    <PostWrapper to={`../${slug}`} key={frontmatter.description}>
      <ImageWrapper>
        <GatsbyImage image={image} alt={frontmatter.title} />
      </ImageWrapper>
      <div className="post-card-content">
        <Badge>{currentCategory}</Badge>
        <PostCardTitle>{frontmatter.title}</PostCardTitle>
        <PostCardDescription>{frontmatter.description}</PostCardDescription>
        <AuthorBlock author={author} />
      </div>
    </PostWrapper>
  );
}
